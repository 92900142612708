import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

// Import Bootstrap CSS and JS
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

// Import required styles
import './styles/main.scss';

// Import components and hooks
import Home from './pages/Home/Home';
import Landing from './pages/Landing/Landing';
import Web from './pages/Web/Web';
import Terms from './pages/Terms/Terms';
import Privacy from './pages/Privacy/Privacy';
import NotFound from './pages/NotFound/NotFound';
import Layout from './components/Layout/Layout';
import useSmoothScroll from './hooks/useSmothScroll/useSmothScroll';
import SEO from './components/SEO/SEO';
import { Type } from 'lucide-react';

// Initialize Google Analytics
ReactGA.initialize("G-3FS702E55Y");

function App() {
  useSmoothScroll();
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return (
    <Layout>
      <SEO
        title="Top-tier websites for brands and entrepreneurs – VPixel CO"
        description="We design and build marketing websites to help grow and strengthen brands, entrepreneurs, and businesses worldwide."
        ogImage="https://static.vpixel.co/OG-v2-Final-EN.jpg"
        favicon="https://static.vpixel.co/favicon-32x32.png"
        appleFavicon="https://static.vpixel.co/apple-touch-icon.png"
      />
      <Routes>
        <Route index element={<Home />} />
        <Route path="/landing" element={<Landing />} />
        <Route path="/web" element={<Web />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Layout>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
