import React from 'react';

const News = ({ bookingCall }) => {
    return (
        <>


            <section className="news">
                <div className="container">
                    <div className="news__content">
                        <p>
                            <span>
                                <span className="color-yellow">Hurry up! &nbsp;</span>
                                We're accepting the last batch of projects for the Q4 2024.
                            </span>
                            <a target="_blank" rel="noopener noreferrer" href={bookingCall} className="button button--bordered">Grab your spot now! <span className="arrow">&rarr;</span></a>
                        </p>

                    </div>
                </div>
            </section>

        </>
    );
};

export default News;
