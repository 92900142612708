import React from "react";
import Logos from '../../components/Logos/Logos';
import Rating from '../../components/Rating/Rating';
import ProjectsList from '../../components/Projectfeed/Projectfeed';
import Slider from '../../components/Slider/Slider';
import Brands from '../../components/Brands/Brands';
import Testimonials from '../../components/Testimonials/Testimonials';
import PricingWeb from '../../components/Pricing/PricingWeb';

import Faqs from '../../components/Faqs/Faqs';
import SEO from '../../components/SEO/SEO';
import News from '../../components/News/News';
import constants from '../../constants.json';


const Home = () => {
    return (
        <>
            <SEO
                title="Top-tier websites for brands and entrepreneurs – VPixel CO"
                description="We design and build marketing websites to help grow and strengthen brands, entrepreneurs, and businesses worldwide."
                ogImage="https://static.vpixel.co/OG-v2-Final-EN.jpg"
                favicon="https://static.vpixel.co/favicon-32x32.png"
                appleFavicon="https://static.vpixel.co/apple-touch-icon.png"
            />


            <div style={{ backgroundImage: 'url(/images/hero-background-2.svg?v=1.1)' }} className="hero hero-home nostatic">
                <div className="container">
                    <div className="title flex-column d-flex">
                        <Rating />
                        <h1 className="h-title title-gradient">
                            Amazing websites that drive nurtured and ready to buy leads <span className="color-yellow">for your business</span>
                        </h1>
                        <p className="lead">
                            We design and build marketing websites to help grow and strengthen brands, entrepreneurs, and businesses worldwide
                        </p>
                        <div className="actions">
                            <a href={constants.site.bookingCall} aria-label="Book a Call Now" className="button">
                                <img src="/images/1665701539301.jpeg" alt="Erick" width="50" />
                                Book a Strategy Call Now! <span className="arrow">&rarr;</span></a>
                        </div>
                        <p className="small">Fixed costs. No lock-ins. Great results.</p>
                    </div>
                </div>
            </div>

            <div className="logos-section">
                <div className="container">
                    <Logos />
                </div>
            </div>

            <News bookingCall={constants.site.bookingCall} />

            <section id='about' className="about-us section nostatic">
                <div className="container">
                    <div className="row">
                        <div className="col-12 ">
                            <div className="title">
                                <span className="subtitle">We are VPixel CO</span>
                                <h2 className="title-gradient">Hey! Is nice to <span className="color-yellow">meet you!</span></h2>
                            </div>
                        </div>
                        <div className="col-12">
                            <p className='about-us__description'>
                                We're a group of talented <span>designers <img src="/images/people/girl.webp" alt="" width="29" /></span>,
                                <span>developers <img src="/images/people/erick.webp" alt="" width="35" /></span>, and
                                <span> marketing strategists <img src="/images/people/mark.webp" alt="" width="40" /></span>
                                working remotely from Barranquilla and Bogotá, Colombia 🇨🇴 that shares a passion for creating beautiful, functional, and effective websites.
                            </p>
                            <p>Taking a thoughtful, relaxed approach to our work, focusing on quality over speed. Rather than chasing fast growth or constant expansion, we choose to work on meaningful projects at our own pace, delivering exceptional digital experiences for businesses and agencies alike.</p>
                        </div>
                    </div>

                </div>
            </section>





            <section className="info">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="title">
                                <h2 className="title-gradient">And yes – we already know the <span className="color-yellow">song...</span></h2>
                                <p className="lead">Your website or app is not driving <span className="color-yellow">enough clients and revenue to your business, right? <img src="/images/people/mad.webp" alt="" width="32" /></span>, and you know that THIS is the real challenge, however, you could also face:</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="grid grid-3">
                                <div className="grid-item d-flex flex-column align-items-center">
                                    <img src="/images/icons/money-receive-svgrepo-com.svg" alt="" />
                                    <h3>Website pricing <span className='color-yellow'>out of reach</span></h3>
                                    <p>You're looking for a nice and professional website but prices from $4000 up to $10.000+ are not an option for you.</p>
                                </div>
                                <div className="grid-item d-flex flex-column align-items-center">
                                    <img src="/images/icons/meh-o-svgrepo-com.svg" alt="" />
                                    <h3>Leads not <span className='color-yellow'>interacting</span></h3>
                                    <p>Having difficulty converting website visitors into leads? Your strategies may be failing to resonate with your audience.</p>
                                </div>
                                <div className="grid-item d-flex flex-column align-items-center">
                                    <img src="/images/icons/convert-3d-cube-svgrepo-com.svg" alt="" />
                                    <h3>Conversion <span className='color-yellow'>challenges</span></h3>
                                    <p>Acquiring clicks is only the beginning. The true test is in transforming those clicks into loyal customers.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="fix nostatic">
                <div className="container">
                    <div className="fix-container">
                        <div className="info">
                            <div className="title">
                                <h2 className="title-gradient">But hey! Don't worry! <span className="color-yellow">We can fix this for you easily <img id='girl-2' src="/images/people/girl-2.webp" alt="" width="40" /> ⚡️</span></h2>
                                <p className="lead">
                                    Your business deserves a powerful digital asset - be it a compelling marketing website, an optimized sales funnel, a high-converting landing page... etc.
                                </p>
                                <p className="lead">
                                    These digital solutions act as tireless, round-the-clock sales representatives, transforming casual visitors into loyal customers. <strong className="color-yellow">Here's how VPixel CO makes this happen:</strong>
                                </p>
                            </div>
                        </div>
                        <div className="cards">
                            <Slider />
                        </div>
                        <div className="cta">
                            <img src="/images/1665701539301.jpeg" alt="Erick" width="50" />
                            <div style={{ gap: '10px' }} className="d-flex flex-column align-items-start">
                                <span>Book a discovery call with our founders to <span className="color-yellow d-block">explore growing your business</span></span>

                                <span className="text">
                                    Allow us to discuss how we work, our process and check if we're a good fit for your business, for free!
                                </span>

                                <a href={constants.site.bookingCall} aria-label="Book a Call Now" className="button">Book Now <span className="arrow">&rarr;</span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id='portfolio' className="projects">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="title">
                                <div>
                                    <span className="subtitle">Results? We got them!</span>
                                    <h2 className="title-gradient">Explore our most <span className="color-yellow">recent projects</span></h2>
                                </div>
                                <p className='lead'>
                                    From bustling cities in Colombia to the shores of Australia and beyond. <span className="color-yellow">We deliver high-value, top-quality, and scalable digital assets - marketing websites, sales and conversion funnels, landing pages</span> - that not only meet expectations but consistently exceed them, driving tangible results and fostering growth.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="projects__grid">
                                <div className="text">
                                    <div className="info d-flex flex-column">
                                        <h3>Creative work that drives amazing results <span>for our clients</span></h3>
                                        <Rating />
                                    </div>
                                </div>
                                <ProjectsList />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="tech">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="title">
                                <div>
                                    <span className="subtitle">Crafting digital experiences, pixel by pixel and line by line</span>
                                    <h2 className="title-gradient">Modern technology to create <span className="color-yellow">magical websites</span></h2>
                                </div>
                                <p className="lead">
                                    At VPixel CO, we leverage the latest technologies to craft Landing Pages, Marketing Sites, eCommerce stores, and applications that are both beautiful, functional and scalable.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-10">
                            <Brands />
                        </div>
                    </div>
                </div>
            </section>

            <PricingWeb />

            <Testimonials />

            <section className="services">
                <div id="services" style={{ position: 'relative', top: '-150px' }}></div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="title">
                                <div>
                                    <span className="subtitle">Our services, our passion and our mission</span>
                                    <h2 className="title-gradient">We craft websites for companies <span className="color-yellow">around the world</span></h2>
                                </div>
                                <p className="lead">
                                    At VPixel CO, we offer a comprehensive range of services tailored to meet the diverse needs of businesses across various industries.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="services__content d-flex flex-column">
                                <div className="side">
                                    {['Landing Pages', 'Marketing Websites', 'Corporate Websites', 'E-Commerce Stores', 'Branding & Logo Design', 'Multi-language Websites', 'UI/UX Design', 'Sales and Marketing Funnels', 'Frontend Development'].map((item, index) => (
                                        <span key={index} className="item nostatic">{item}</span>
                                    ))}
                                </div>

                                <div className="middle">
                                    <h2 className="title-gradient">For several <span className='color-yellow'>markets and industries</span></h2>
                                </div>

                                <div className="side">
                                    {['SaaS', 'Startups', 'Entrepreneurs', 'Health Care', 'Real Estate', 'B2B Companies', 'Transport & Logistics', 'Legal', 'Non-Profit Organizations', 'Government', 'Marketing Agencies'].map((item, index) => (
                                        <span key={index} className="item nostatic">{item}</span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Faqs />

            <section id='contact' className="call">
                <div className="container">
                    <div className="row">
                        <div className="col-12 d-flex flex-column">
                            <div className="title">
                                <span className="subtitle">Convinced? GOOD! Let's take the next step</span>
                                <h2 className="title-gradient">Claim your FREE 15-min <span className="color-yellow">Strategy Call</span></h2>
                                <p className="lead">
                                    Take the first step towards transforming your business with a personalized strategy session, where we'll discuss your goals, challenges, and how we can help you achieve your digital transformation objectives.
                                </p>
                            </div>
                            <div className="actions">
                                <a href={constants.site.bookingCall} aria-label="Book a Call Today!" target="_blank" rel="noopener noreferrer" className="button">Book a Call Today!</a>
                                <Rating />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Home;
