import React from "react";

import "./styles.css";

import { slate } from "./constants";

const ITEM_COUNT = 10;
const ITEM_GAP = 20;

const PACE = 5;
const DURATION = ITEM_COUNT * PACE;

export default function App() {
    const logos = slate.slice(0, ITEM_COUNT);

    let totalWidth = logos.reduce((res, cur) => res + cur.width + ITEM_GAP, 0);

    let maxItemSize = 0;
    logos.forEach((l) => {
        maxItemSize = Math.max(maxItemSize, l.width + ITEM_GAP);
    });
    let accXPos = 0;
    const elPos = logos.map((l) => {
        const elXPos = accXPos;
        const elStart = totalWidth - elXPos - maxItemSize;
        const elEnd = 0 - elXPos - maxItemSize;
        const distance = elStart - elEnd;
        const elIndex = elXPos / (distance / ITEM_COUNT);

        accXPos += l.width + ITEM_GAP;
        return [elStart, elEnd, elIndex];
    });

    return (
        <>
            <div className="scene">
                <ul
                    className="marquee"
                    key={ITEM_COUNT}
                    style={{
                        "--count": ITEM_COUNT,
                        "--duration": `${DURATION}s`,
                        "--item-gap": `${ITEM_GAP}px`,
                    }}
                >
                    {logos.map((l, idx) => {
                        const [item_start, item_end, item_index] = elPos[idx];
                        const item_delay = PACE * (item_index - ITEM_COUNT);

                        return (
                            <li
                                key={`${ITEM_COUNT}_${l.companyName}`}
                                className="marquee_item"
                                style={{
                                    "--index": `${item_index}`,
                                    "--delay": `${item_delay}s`,
                                    "--start": `${item_start}px`,
                                    "--end": `${item_end}px`,
                                }}
                            >
                                <img
                                    src={l.logoUrl}
                                    alt={l.companyName}
                                    width={l.width}
                                    height={l.height}
                                />
                            </li>
                        );
                    })}
                </ul>
            </div>
        </>
    );
}
