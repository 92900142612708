import React from 'react';

const brands = [
    {
        name: 'Wordpress',
        logo: 'https://cdn.brandfetch.io/idbnlnCBDY/theme/dark/symbol.svg',
    },
    {
        name: 'Azure',
        logo: 'https://cdn.brandfetch.io/idgS27aNck/theme/dark/symbol.svg'
    },
    {
        name: 'PHP',
        logo: 'https://cdn.brandfetch.io/idYqAg6C_T/theme/dark/logo.svg'
    },
    {
        name: 'React JS',
        logo: 'https://cdn.brandfetch.io/idREYlLkpD/theme/dark/logo.svg'
    },
    {
        name: 'Laravel',
        logo: 'https://cdn.brandfetch.io/ide68-31CH/theme/dark/logo.svg'
    },
    {
        name: 'Vue.js',
        logo: 'https://cdn.brandfetch.io/idT-7aLCAj/theme/dark/logo.svg'
    },
    {
        name: 'AWS',
        logo: 'https://cdn.brandfetch.io/idVoqFQ-78/theme/dark/logo.svg'
    },
    {
        name: 'Bootstrap',
        logo: 'https://cdn.brandfetch.io/id4Ha8BARu/theme/dark/symbol.svg'
    },
    {
        name: 'ChatGPT',
        logo: '/images/chatgpt.svg'
    },
    {
        name: 'Elementor Pro    ',
        logo: '/images/elementor.png'
    },
]

const Brands = () => {
    return (
        <>
            <div className='brands'>
                {brands.map((brand) => (
                    <div
                        className='brand'
                        key={brand.name}
                    >
                        <figure>
                            <img src={brand.logo} alt={brand.name} />
                        </figure>
                        <figcaption>{brand.name}</figcaption>
                    </div>
                ))}
            </div>
        </>
    );
};

export default Brands;