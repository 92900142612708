import React from "react";

import Logos from '../../components/Logos/Logos';
import Rating from '../../components/Rating/Rating';
import ProjectsList from '../../components/Projectfeed/Projectfeed';
import InfoCards from '../../components/InfoCards/InfoCards';
// import Pricing from '../../components/Pricing/Pricing';
import FaqsEs from '../../components/FaqsEs/FaqsEs';
import SEO from '../../components/SEO/SEO';
import constants from '../../constants.json';
import Compare from '../../components/Compare/Compare';
const Landing = () => {
    // const discountValue = 10;

    return (
        <div className="web">
            <SEO
                title="Sitios web para emprendedores en descuento - VPixel CO"
                description="Solicite su Sitio Web especializado y comience a construir su éxito online hoy."
                ogImage="/images/landing/OG-v2-Final-EN.jpg"
                favicon="https://static.vpixel.co/favicon-32x32.png"
                appleFavicon="https://static.vpixel.co/apple-touch-icon.png"
            />


            <section id='about' className="about-us section nostatic" style={{ backgroundImage: 'url(/images/landing/Frame461.jpg)' }}>
                <div className="container">
                    <div className="hero-container grid grid-2">
                        <div className="title flex-column d-flex">
                            <Rating language="es" />
                            <h1 className="h-title title-gradient">
                                Haga crecer su <span className="color-yellow">negocio 24/7  </span>
                            </h1>
                            <p className='about-us__description'>
                                Diseñamos y construimos sitios web que impulsan ventas y posicionamiento de su negocio, para que pueda crecer de forma exponencial.
                            </p>
                            <div className="actions">
                                <a href={constants.site.contactLink} aria-label="¡Cotice hoy!" className="button">Agende una Llamada</a>
                                <a href="#info" aria-label="Más información" className="button button--bordered">Más información</a>
                            </div>
                        </div>
                        <div className="">
                            <img src="/images/landing/hero-landing.webp" alt="" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="logos-section">
                <div className="container">
                    <Logos />
                </div>
            </section>

            <section id="info" className="info">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="title">
                                <span className="subtitle">
                                    ¿Sabía usted que...
                                </span>
                                <h2 className="title-gradient">
                                    El <span className="color-yellow">94%</span> de las primeras impresiones sobre su negocio están relacionadas con el diseño de su página web? <img src="/images/people/question.webp" alt="" className="person-img" />
                                    {/* Ahora bien, ¿Qué es una Landing Page y <span className="color-yellow">cómo puede beneficiar su negocio? <img src="/images/people/question.webp" alt="" className="person-img" /></span> */}
                                </h2>
                                <p className="lead">
                                    El mal diseño de su sitio web puede afectar negativamente su negocio haciendo que pierda credibilidad, posicionamiento y finalmente, clientes
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="compare-section">
                <div className="container">
                    <div className="grid grid-2">
                        <div className="compare">
                            <span className="compare-title">
                                Otros sitios 🥱
                            </span>
                            <img className="img-fluid" src="/images/landing/old.webp" alt="" />
                            <ul className="list-unstyled">
                                <li><span className="emoji">❌</span> Diseño poco atractivo y desactualizado</li>
                                <li><span className="emoji">❌</span> Dificultad para encontrar información</li>
                                <li><span className="emoji">❌</span> Experiencia pobre y no intuitiva</li>
                                <li><span className="emoji">❌</span> Problemas de velocidad y rendimiento</li>
                                <li><span className="emoji">❌</span> Sin posibilidad de actualización</li>
                            </ul>
                        </div>
                        <div className="compare good">
                            <span className="compare-title">
                                Nuestros sitios 🤩
                            </span>
                            <img className="img-fluid" src="/images/landing/new.webp" alt="" />
                            <ul className="list-unstyled">
                                <li><span className="emoji">✅</span> Diseño optimizado para conversiones</li>
                                <li><span className="emoji">✅</span> Diseño moderno, único y profesional</li>
                                <li><span className="emoji">✅</span> Fácil navegación</li>
                                <li><span className="emoji">✅</span> Rápido, seguro y confiable</li>
                                <li><span className="emoji">✅</span> Fácil de entender y manejar</li>
                            </ul>
                        </div>
                    </div>
                </div>


                <div className="container info">
                    <div className="container">
                        <div className="col-12 col-md-8">
                            <div className="title">
                                <span className="subtitle">
                                    Nuestra misión
                                </span>
                                <h2 className="title-gradient title-h1">
                                    Es por esto que en VPixel CO diseñamos y construimos sitios web <span className="color-yellow">increíbles.</span>
                                </h2>
                                <p className="lead">
                                    Nos esforzamos por crear sitios web que no solo sean visualmente atractivos, sino que también sean fáciles de navegar y entender, generando una experiencia de usuario excepcional sin perder la esencia de su marca ni sus objetivos comerciales.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="fix">
                <div className="container">
                    <div className="col-12">
                        <div className="title">
                            <h2 className="title-gradient title-h1">
                                Y ¿Cómo lo hacemos?
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="fix-container">
                        <div className="cards">
                            <InfoCards />
                        </div>
                    </div>
                </div>
                <div className="container info">
                    <div className="row">
                        <div className="col-12">
                            <div className="title">
                                <h2 className="title-gradient title-h1">
                                    Antes vs <span className="color-yellow">Después</span>
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">

                            <Compare />

                            {/* <Compare /> 
                            <img className="img-fluid before-after-desktop" src="/images/landing/oldvsnew.webp" alt="" />
                            <img className="img-fluid before-after-mobile" src="/images/landing/oldvsnew-mobile.webp" alt="" />
                                */ }
                        </div>
                    </div>
                </div>
            </section>

            <section id='portfolio' className="projects">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <header className="title">
                                <h2 className="title-gradient">
                                    Ejemplos y Resultados: <span className="color-yellow">¿Cómo quedan nuestros sitios web? <img src="/images/people/girl-4.webp" alt="" className="person-img" /></span>
                                </h2>
                                <p className='lead'>
                                    Permítanos mostrarle la diferencia que un mejor sitio web puede hacer para su negocio: aumentando interacciones, incrementando las conversiones y brindando a sus clientes una experiencia que no olvidarán.
                                </p>
                            </header>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="projects__grid">
                                <div className="text">
                                    <div className="info d-flex flex-column">
                                        <h3>Resultados <span className="color-yellow">comprobables</span></h3>
                                        <p>
                                            Diseñamos y desarrollamos sitios web de alto impacto combinando creatividad, tecnología avanzada y estrategias probadas para maximizar conversiones y potenciar el crecimiento de su negocio en línea.
                                        </p>
                                        <Rating language="es" />
                                        <a target="_blank" rel="noopener noreferrer" href={constants.site.contactLink} data-service="landing" className="button">
                                            Solicite una cotización
                                        </a>
                                    </div>
                                </div>
                                <ProjectsList language="es" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="data">
                <div className="container">
                    <div className="row row-vh">
                        <div className="col-12 col-md-8">
                            <header className="title">
                                <h2 className="title-gradient">
                                    Posicione su negocio y aumente sus ventas <img src="/images/people/girl.webp" alt="" className="person-img" /> de forma <span className="color-yellow">sencilla, rápida y efectiva.</span>
                                </h2>
                            </header>
                        </div>
                    </div>

                    <div className="row data-row">
                        <div className="col-12 col-md-4">
                            <article className="data__item">
                                <h3 className="color-yellow">78%</h3>
                                <p>
                                    De los usuarios que visitan un Sitio Web especializado realizan la acción deseada ya sea comprar un producto, descargar un ebook o suscribirse a un boletín informativo.
                                </p>
                            </article>
                        </div>

                        <div className="col-12 col-md-4">
                            <article className="data__item">
                                <h3 className="color-yellow">75%</h3>
                                <p>
                                    De los consumidores admiten hacer juicios sobre la credibilidad de una empresa basada en el diseño de su sitio web.
                                </p>
                            </article>
                        </div>


                        <div className="col-12 col-md-4">
                            <article className="data__item">
                                <h3 className="color-yellow">61%</h3>
                                <p>
                                    De los usuarios no regresarán a un sitio si tuvieron dificultades para acceder a él y el 40% visitará el sitio de una competidora en su lugar.
                                </p>
                            </article>
                        </div>

                        <div className="col-12 col-md-4">
                            <article className="data__item">
                                <h3 className="color-yellow">200%</h3>
                                <p>
                                    Es el aumento del ROI (Retorno de inversión) que perciben en promedio los negocios que manejan un sitio web especializado para promocionar su servicio o producto.
                                </p>
                            </article>
                        </div>
                        <div className="col-12 col-md-4">
                            <article className="data__item">
                                <h3 className="color-yellow">50%</h3>
                                <p>
                                    De más clics e interacciones pues al tener una llamada a la acción convincente, las páginas web bien diseñadas animan a más usuarios a realizar la acción deseada.
                                </p>
                            </article>
                        </div>


                        <div className="col-12 col-md-4">
                            <article className="data__item">
                                <h3 className="color-yellow">88%</h3>
                                <p>
                                    De los usuarios online son menos propensos a regresar a un sitio después de una mala experiencia.
                                </p>
                            </article>
                        </div>


                    </div>
                </div>
            </section>

            {/* <Pricing discountValue={discountValue} /> */}

            <section id='contact' className="call">
                <div className="container">
                    <div className="row">
                        <div className="col-12 d-flex flex-column">
                            <header className="title">
                                <span className="subtitle">¿Convencido? Demos el siguiente paso</span>
                                <h2 className="title-gradient">Empecemos su proyecto digital <span className="color-yellow">hoy mismo</span></h2>
                            </header>
                            <div className="actions">
                                <p className="small"><strong className="color-yellow">
                                    Hablemos por WhatsApp. </strong> Hable directamente con nuestro equipo de expertos y resuelva todas sus dudas</p>
                                <a href={constants.site.contactLink} aria-label="Hablemos ¡Ya mismo!" className="button">+57 318 851 3737</a>
                                <Rating language="es" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <FaqsEs />

            <section className="warranty">
                <div className="container">
                    <div className="warranty__content">

                        <div className='warranty__info'>
                            <div className="title">
                                <h2 className="title-gradient">
                                    Protegemos su inversión con una <span className="color-yellow">garantía total</span>
                                </h2>
                            </div>
                            <img src="/images/landing/warranty.png" alt="Garantía de satisfacción" />

                            <p className="lead">
                                Sin complicaciones ni trámites engorrosos, le ofrecemos un reembolso del 100% de lo que haya pagado si siente que nuestro trabajo no está completamente alineado con sus expectativas o las expectativas que tiene para tu marca o emprendimiento.
                            </p>
                            <p className="small">
                                <strong>Es muy sencillo: </strong>Compártanos sus razones y solicite el reembolso. En un plazo de 2 a 5 días hábiles, procesaremos su solicitud y el monto será reintegrado a su cuenta. VPixel CO opera principalmente con Bancolombia, pero trabajamos con todos los bancos nacionales. Tenga en cuenta que los tiempos de acreditación pueden variar según su entidad bancaria.
                            </p>
                        </div>

                    </div>
                </div>
            </section >


        </div>
    );
};

export default Landing;
