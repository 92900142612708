export const translations = {
    en: {
        customerRating: "Customer rating of",
        ratingValue: "4.91/5",
        foundersAlt: "Founders",
        seeThisLive: "See it live"
    },
    es: {
        customerRating: "Calificación de clientes de",
        ratingValue: "4,91/5",
        foundersAlt: "Fundadores",
        seeThisLive: "Verlo en vivo"
    },
    // Add more languages as needed
};