export const slate = [
    {
        companyName: "Logo 8",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/8.webp",
    },
    {
        companyName: "Logo 34",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/34.webp",
    },
    {
        companyName: "Logo 17",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/17.webp",
    },
    {
        companyName: "Logo 7",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/7.webp",
    },
    {
        companyName: "Logo 11",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/11.webp",
    },
    {
        companyName: "Logo 9",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/9.webp",
    },
    {
        companyName: "Logo 15",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/15.webp",
    },
    {
        companyName: "Logo 22",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/22.webp",
    },
    {
        companyName: "Logo 25",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/25.webp",
    },
    {
        companyName: "Logo 20",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/20.webp",
    },
    {
        companyName: "Logo 18",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/18.webp",
    },
    {
        companyName: "Logo 13",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/13.webp",
    },
    {
        companyName: "Logo 19",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/19.webp",
    },
    {
        companyName: "Logo 23",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/23.webp",
    },
    {
        companyName: "Logo 10",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/10.webp",
    },
    {
        companyName: "Logo 31",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/31.webp",
    },
    {
        companyName: "Logo 35",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/35.webp",
    },
    {
        companyName: "Logo 16",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/16.webp",
    },
    {
        companyName: "Logo 21",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/21.webp",
    },
    {
        companyName: "Logo 6",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/6.webp",
    },
    {
        companyName: "Logo 29",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/29.webp",
    },
    {
        companyName: "Logo 26",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/26.webp",
    },
    {
        companyName: "Logo 4",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/4.webp",
    },
    {
        companyName: "Logo 33",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/33.webp",
    },
    {
        companyName: "Logo 24",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/24.webp",
    },
    {
        companyName: "Logo 32",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/32.webp",
    },
    {
        companyName: "Logo 2",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/2.webp",
    },
    {
        companyName: "Logo 28",
        width: 204,
        height: 100,
        logoUrl: "/images/logos/28.webp",
    },
];
