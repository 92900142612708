import React, { useState, useEffect } from 'react'
import './Testimonials.css'

const Testimonials = () => {
    const [testimonialsData, setTestimonialsData] = useState([]);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);
    const [displayCount, setDisplayCount] = useState(isMobile ? 3 : testimonialsData.length);

    useEffect(() => {
        const fetchTestimonials = async () => {
            try {
                const response = await fetch('https://wp.vpixel.co/wp-json/wp/v2/quotes?per_page=50&_embed');
                const data = await response.json();
                setTestimonialsData(data);
                setDisplayCount(isMobile ? 3 : data.length);
            } catch (error) {
                console.error('Error fetching testimonials:', error);
            }
        };

        fetchTestimonials();
    }, [isMobile]);

    useEffect(() => {
        const handleResize = () => {
            const newIsMobile = window.innerWidth <= 800;
            setIsMobile(newIsMobile);
            setDisplayCount(newIsMobile ? 3 : testimonialsData.length);
        };

        // Initial call
        handleResize();

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [testimonialsData.length]);

    const handleViewMore = () => {
        setDisplayCount(prevCount => Math.min(prevCount + 3, testimonialsData.length));
    };

    const StarIcon = () => (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 -10 187.673 179.503"
            fill="var(--color-yellow)"
            stroke="currentColor"
            strokeWidth="0"
            aria-hidden="true"
        >
            <path d="M187.183 57.47a9.955 9.955 0 00-8.587-6.86l-54.167-4.918-21.42-50.134a9.978 9.978 0 00-9.172-6.052 9.972 9.972 0 00-9.172 6.061l-21.42 50.125L9.07 50.611a9.973 9.973 0 00-8.578 6.858 9.964 9.964 0 002.917 10.596l40.944 35.908-12.073 53.184a9.97 9.97 0 003.878 10.298A9.953 9.953 0 0042 169.357a9.937 9.937 0 005.114-1.424l46.724-27.925 46.707 27.925a9.936 9.936 0 0010.964-.478 9.979 9.979 0 003.88-10.298l-12.074-53.184 40.944-35.9a9.98 9.98 0 002.925-10.604zm0 0" />
        </svg>
    );

    const displayedTestimonials = testimonialsData.slice(0, displayCount);

    return (
        <section className="testimonials">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="title">
                            <div>
                                <span className="subtitle">Testimonials</span>
                                <h2 className="title-gradient">Nice words from our <span className="color-yellow">happy clients</span></h2>
                            </div>
                            <p className="lead"></p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="testimonials__container">
                            {displayedTestimonials.map((testimonial) => (
                                <div key={testimonial.id} className="testimonial">
                                    <div className="testimonial__content">
                                        <div
                                            className="testimonial__quote"
                                            dangerouslySetInnerHTML={{
                                                __html: testimonial.content.rendered
                                            }}
                                        />
                                    </div>
                                    <div className="testimonial__image d-flex align-items-center">
                                        {testimonial._embedded?.["wp:featuredmedia"]?.[0]?.source_url && (
                                            <img
                                                src={testimonial._embedded["wp:featuredmedia"][0].source_url}
                                                alt={testimonial.title.rendered}
                                                loading="lazy"
                                            />
                                        )}
                                        <div>
                                            <p className="testimonial__name">
                                                {testimonial.title.rendered}
                                            </p>
                                            <p className="testimonial__position">
                                                {testimonial.acf.position}
                                            </p>
                                            <div className="rating">
                                                {[...Array(5)].map((_, index) => (
                                                    <StarIcon key={index} />
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {isMobile && displayCount < testimonialsData.length && (
                            <div className="text-center mt-4">
                                <button
                                    className="button"
                                    onClick={handleViewMore}
                                    aria-label="View more testimonials"
                                >
                                    View More
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Testimonials